import styles from "./index.module.scss";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import atavar1 from "@images/home/atavar1.png";
import atavarVi from "@images/home/avatar-vi.png";
import atavar2 from "@images/home/atavar2.png";
import atavar3 from "@images/home/atavar3.png";
import atavar4 from "@images/home/atavar4.png";
import maohaoPic from "@images/home/maohao.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css/pagination";
import "swiper/css";
type Props = {};
const PcReviews = (props) => {
  const { list } = props;
  const {t} = useTranslation("home")
  return (
    <Swiper
      spaceBetween={12}
      slidesPerView={"auto"}
      modules={[Pagination]}
      pagination={{
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
        bulletClass: ` ${styles.myBullet} swiper-pagination-bullet`,
        bulletActiveClass: `${styles.myActiveBullet} swiper-pagination-bullet-active`,
      }}
    >
      <SwiperSlide className={styles.itemBox}>
        {list.slice(0, 2).map((el, index) => {
          return (
            <div className={`${styles.item} ant`} key={index}>
              <div>
                {/* <div className={styles.icon}> */}
                  <Image width={22} height={22} alt="" src={maohaoPic}></Image>
                {/* </div> */}
                <div className={styles.reviewTitle}>{t(`reviewsTitle${el.index}`)}</div>
                <div className={styles.content}>&quot;<span dangerouslySetInnerHTML={{__html: t(`reviewsTip${el.index}`)}}></span>&quot;</div>
              </div>

              <div className="flex items-center mt-[47px] ">
                <div className={styles.atavar}>
                  <Image alt="" src={el.atavar}></Image>
                </div>
                <div className={styles.name}> {el.name}</div>
                <div
                  className={`${styles.type} ${
                    el.typeblue ? styles.typeblue : ""
                  }`}
                >
                  {t(`reviewsType${el.index}`)}
                </div>
              </div>
            </div>
          );
        })}
      </SwiperSlide>
      <SwiperSlide className={styles.itemBox}>
        {list.slice(2, 4).map((el, index) => {
          return (
            <div className={`${styles.item} ant`} key={index}>
              <div>
                <div className={styles.icon}>
                  <Image alt="" src={maohaoPic}></Image>
                </div>
                <div className={styles.reviewTitle}>{t(`reviewsTitle${el.index}`)}</div>
                <div className={styles.content}>&quot;<span dangerouslySetInnerHTML={{__html: t(`reviewsTip${el.index}`)}}></span>&quot;</div>
              </div>

              <div className="flex items-center mt-[47px] ">
                <div className={styles.atavar}>
                  <Image alt="" src={el.atavar}></Image>
                </div>
                <div className={styles.name}> {el.name}</div>
                <div
                  className={`${styles.type} ${
                    el.typeblue ? styles.typeblue : ""
                  }`}
                >
                  {t(`reviewsType${el.index}`)}
                </div>
              </div>
            </div>
          );
        })}
      </SwiperSlide>
      <div className={`swiper-pagination ${styles.myPagination}`}></div>
    </Swiper>
  );
};
const MReviews = (props)=>{
    const {list} = props;
    const {t} = useTranslation("home")
    return (
        <Swiper
        spaceBetween={12}
        slidesPerView={"auto"}
        modules={[Pagination]}
        pagination={{
            el: ".swiper-pagination",
            type: "bullets",
            clickable: true,
            bulletClass: ` ${styles.myBullet} swiper-pagination-bullet`,
            bulletActiveClass: `${styles.myActiveBullet} swiper-pagination-bullet-active`,
        }}
    >
        {list?.map((el, index) => {
            return (
                <SwiperSlide
                    key={index}
                    className={styles.itemBox}
                >
                    <div className={`${styles.item}`} key={index}>
                <div>
                  {/* <div className={styles.icon}> */}
                    <Image width={22} height={22} alt="" src={maohaoPic}></Image>
                  {/* </div> */}
                  <div className={styles.reviewTitle}>{t(`reviewsTitle${el.index}`)}</div>
                  <div className={styles.content}>&quot;<span dangerouslySetInnerHTML={{__html: t(`reviewsTip${el.index}`)}}></span>&quot;</div>
                </div>

                <div className="flex items-center mt-[47px] ">
                  <div className={styles.atavar}>
                    <Image alt="" src={el.atavar}></Image>
                  </div>
                  <div className={styles.name}> {el.name}</div>
                  <div
                    className={`${styles.type} ${
                      el.typeblue ? styles.typeblue : ""
                    }`}
                  >
                    {t(`reviewsType${el.index}`)}
                  </div>
                </div>
              </div>
                </SwiperSlide>
            );
        })}
        <div
            className={`swiper-pagination ${styles.myPagination}`}
        ></div>
    </Swiper>
    )
}
const Index = (props: Props) => {
  const { t, i18n } = useTranslation("home");
  const list = [
    i18n.language === 'vi' ?
      {
        index: 1,
        name: 'Z15 Agency',
        atavar: atavarVi,
        typeblue: false
      } :
    {
      index: 1,
      name: ` Allen`,
      atavar: atavar1,
      typeblue: false,
    },
    {
      index: 2,
      name: `Charli`,
      atavar: atavar2,
      typeblue: true,
    },
    {
      index: 3,
      name: `Khaby`,
      atavar: atavar3,
      type: t("beautyMerchant"),
      typeblue: false,
    },
    {
      index: 4,
      name: `Keyla`,
      atavar: atavar4,
      typeblue: true,
    },
  ];
  return (
    <div className={styles["review-box"]}>
        <h2 className={styles.title}>{t("customerReviews")}</h2>
        <div className={`animate-box ${styles.PcReviews} defeaultContainer`}>
          <PcReviews list={list}></PcReviews>
        </div>

        <div className={styles.MReviews}>
            <MReviews list={list}></MReviews>
        </div>
    </div>
  );
};
export default Index;
